.subscribe__container{
  height: 320px;
  width: 100%;
  padding-top: 4%;
  overflow: hidden;
  background-image: url("../img/subscribe_bg.png");
  background-repeat: no-repeat;
}
.subscribe__container span{
  color: #fff;
  font-size: 3.7rem;
  font-family: "GothaProBol",sans-serif;
  display: block;
  margin-bottom: 10%;
  text-align: center;
}
.subscribe__container form{
  padding-top: 9px;
}
.subscribe__container input[type="e-mail"]{
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #fff;
  display: block;
  margin-bottom: 20px;
  padding: 0;
  padding-bottom: 5px;
  padding-left: 5px;
  width: 100%;
  font-size: 1.5rem;
  outline: 0;
}
.subscribe__container input::placeholder{
  font-size: 1.1rem;
  color:#fff;
}
.button__subscribe{
  width: 160px;
  margin: 0 auto;
  &:hover{
    background-color: rgba(255,255,255,0.3);
  }
  // &:after{
  //   display: block;
  //   content: '';
  //   background-color: #fff;
  //   position: absolute;
  //   right: -10px;
  //   top:18px;
  //   height: 1px;
  //   width: 22px;
  // }
}
.subscribe__container input[type="submit"]{
  margin: 0 auto;
  color: #fff;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}


.partners__wrapper{
  height: 300px;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 50px;
}
.partners_title {
  font-size: 4.1rem;
  margin-bottom: 5%;
  margin-left: 1%;
  font-family: 'GothaProBol',sans-serif;
}
.partners__wrapper ul {
  font-size: 0;
  margin: 0;
  padding: 0;
  max-height: 100px;
  overflow: hidden;
}
.partners__wrapper ul li{
  display: inline-block;
  list-style-type: none;
  width: 20%;
  height: 100px;
  font-size: 1rem;
  vertical-align: top;
  text-align: center;
  position: relative;
}
.partners__wrapper a{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:hover{
  }
}
.partners__control{
  top: 63%;
  z-index: 1;
}
@media (max-width: 1198px){
  .partners__control{
    top: 60%;
  }
}
@media (max-width: 992px){
  .partners__control{
    top: 55%;
  }
}
@media (max-width: 814px){
  .partners__control.right__control{
      right: 3%;
  }
  .partners__control.left__control{
      left: 3%;
  }
}
@media (max-width: 767px){
  .partners__wrapper ul li{
    width:33%;
  }
}
@media (max-width: 600px){
  .partners__wrapper ul li{
    width:50%;
  }
}
@media (max-width: 420px){
  .partners__wrapper ul li{
    width:100%;
  }
}

//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;
input[placeholder]{
  color: #fff;
}
.form__wrapper{
  min-height: 260px;
  background-color: $brand_color;
  font-size: 0;
  color: #fff;
}
.form__block{
  display: inline-block;
  width: 50%;
  font-size: 1.2rem;
  vertical-align: top;
}
.form__block_left{
  padding-top: 5%;
}
.form__block_right{
  margin-top: 4%;
}
.form__block .form__title{
  font-size: 3rem;
  padding-top: 7%;
  font-family: "GothaProBla", sans-serif;
}
.form__block span{
  display: block;
  width: 71%;
  font-size: 1.7rem;
  padding-top: 10px;
  text-align: right;
  letter-spacing: 1px;
}
.form__block input{
  background-color: #0e3e6d;
  border: none;
  border-bottom: 1px solid;
  padding-left: 15px;
  height:43px;
  outline: 0;
  width: 45%;
}
.form__block input::placeholder{
  color: #fff;
}
.form__block input[name="phone"]{
  float: right;
}
.form__block input[name="name"]{
  display: block;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 4%;
}
.form__input-group{
  width: 85%;
  margin: 0 auto;
}
.button__send{
  width: 170px !important;
  margin: 0 auto;
  margin-top: 15px;
  &:hover{
    background-color: rgba(255,255,255,0.3);
  }
}
.form__block input[type="submit"]{
  margin: 0 auto;
  color: #fff;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}
@media (max-width: 767px){
  .form__block input{
    width: 100%;
  }
  .form__block input[name="phone"]{
    margin-top: 10px;
    float: none;
  }
}
@media (max-width: 550px){
  .form__block{
    display:block;
    width: 100%;
  }
  .form__wrapper{
    padding-bottom: 40px;
  }
}

//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;
// стили для контроллеров слайдера
.controls__container{
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;
}
.controls__item{
  width: 10px;
  height:10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: $brand_color;
  display: inline-block;
  transition: 0.2s;
  &:hover{
    background-color: $red;
  }
}

//бооковые контроллеры
.side__controls{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fafafa;
  position: absolute;
  top: 50%;
  transition: 0.2s;
  &:hover{
    background-color: #aba9a9;
  }
}
.side__controls a {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.side__controls a img{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.left__control{
    left: -2%;
    transform: translateY(-50%);
}
.right__control{
  right: -3%;
  transform: translateY(-50%);
}

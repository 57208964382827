//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;

.page__card img{
  height: 65px;
  width: 65px;
  color: $brand_color;
}
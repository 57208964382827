//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;

// стили для внутренней страницы
.page__title{
  font-family: "GothaProBol",sans-serif;
  font-size: 4rem;
  color: $text_color;
  margin: 55px 0;
}
.page__slider-wrapper{
  height: 540px;
  overflow: hidden;
  position: relative;
  box-shadow: -10px 10px #f3f3f3;
}
.page__slider-controls{
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.page__slider-controls .controls__item{
  background-color:#fff;
  &:hover{
    background-color: $brand_color;
  }
}
.page__slider-wrapper .left__control{
  left: 3%;
}
.page__slider-wrapper .right__control{
  right: 5%
}
.page__description{
  margin-top: 7%;

}
.page__description p{
  line-height: 2;
  margin-bottom: 4%;
  text-align: justify;
}
.page__button{
  border-color: $brand_color;
  color: $brand_color;
  font-family: "GothaProBol",sans-serif;
  text-transform: uppercase;
  &:after{
    background-color: $brand_color;
  }
  &:hover{
    background-color: $brand_color;
  }
}
.page__galery-link{
  display: block;
  overflow: hidden;
  margin-bottom: 35px;
}
.page__galery-link img{
  width:100%;
  height: auto;
}
.page__card {
  font-size: 0;
  margin-bottom: 35px;
}
.page__img-wrapper,.page__card span{
  display: inline-block;
  font-size: 1rem;
}
.page__img-wrapper{
  width: 65px;
  height:65px;
  position: relative;
  border-radius: 50%;
  border:1px solid $text_color;
}
.page__img-wrapper img{
  width: 27px;
  height: 27px;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke:$brand_color;
}
.page__card span{
  width: 60%;
  font-size: 1.2rem;
  vertical-align: top;
  padding-left: 5%;
  line-height: 1.8;
}
.page__fonrm-wrapper{
  margin-top: 40px;
}
.page__form-block-left{
  padding-top: 2%;
}
// конец стилей для внутренней страинцы

//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;

.modal__window,.modal__window_quickorder,.modal__window_product{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(96,96,96,0.5);
  z-index: 1000;
  display: none;
}

.modal__close-btn{
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  border: 1px solid #000;
  border-radius: 50%;
  right: 0;
  top: 0;
  margin: 2%;
}
.modal__close-btn img{
  width: 20px;
  height: 20px;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.modal__form-container span{
  display: block;
  text-align: center;
  color: $brand_color;
}
.modal__title{
  font-size: 3rem;
  padding-top: 7%;
  font-family: "GothaProBla", sans-serif;
  text-align: center;
  color: $brand_color;
}
.modal__form-container{
  position: absolute;
  height: 400px;
  min-width: 550px;
  background: #fff;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.modal__form {
  width: 80%;
  margin: 0 auto;
  margin-top: 5%;
}
.modal__form input{
  border: none;
  border-bottom: 1px solid $brand_color;
  height:43px;
  outline: 0;
}
.modal__form input[type="text"]{
  width: 100%;
}
.modal__form input[type="email"],.modal__form input[type="tel"]{
  width: 45%;
}
.modal__form input[type="tel"]{
  float: right;
}
.modal__form input[type="checkbox"]{
  float: left;
  border: 1px solid $brand_color;
  background-color: #fff;
  border-radius: none;
  outline: none;
}
.modal__form textarea{
  width: 100%;
  margin-top: 5%;
  border: none;
  border-bottom: 1px solid $brand_color;
  outline: 0;
}
.modal__form button{
  margin-top: 5%;
  background-color: #fff;
}
.modal__agree-span{
  width: 95%;
  margin-top: 4%;
}
// modal product
.modal_product{
  font-size: 0;
  padding: 50px 30px;
  height: 500px;
  width: 1000px;
}
.modal__slider, .modal__text{
  display: inline-block;
  width: 50%;
  font-size: 1rem;
  height: 100%;
  overflow: hidden;
  vertical-align: top;
}
.modal__slider-wrapper{
  height: 100%;
  overflow: hidden;
  position: relative;
  margin-right: 30px;
}
.modal__slider-wrapper .left__control{
  left: 2%;
}
.modal__slider-wrapper .right__control{
  right: 2%;
}
.modal__product-title {
  font-size: 1.5rem;
  color: $text_color;
  font-family: 'GothaProBol',sans-serif;
  margin: 20px 0;
}
.modal_product span{
  text-align: left;
}
.modal__text p{
  font-size: 1.2rem;
  line-height: 2;
}
@media (max-width: 1030px){
  .modal_product{
    width: 100%;
  }

}
@media (max-width: 570px){
  .modal__form-container{
    min-width: 0;
    width: 100%;
    height: auto;
    padding-bottom: 5%;
  }
  .modal_product{
    height: 65%;
  }
  .modal__slider, .modal__text{
    display:block;
    width: 100%;
    font-size: 1rem;
    height: 50%;
    overflow: hidden;
    vertical-align: top;
  }
  // .modal__form input[type="checkbox"]{
  //   margin-top: 11.5%;
  // }
  // .modal__agree-span{
  //   margin-top: 15%;
  // }
}

//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;

.page__navigation{
  width: 100%;
  min-height: 40px;
  background-color: $brand_color;
  font-family: "GothaProBol", sans-serif;
}
.page__navigation .page__navigation-main{
  min-height: 40px;
  line-height: 40px;
}
.page__navigation .page__navigation-main a{
  vertical-align: middle;
  color: #fff;
  float: left;
}
.page__navigation .header__navigation-list{
  padding: 0;
  margin-right: 0;
  text-align: right;
  min-height: 40px;
  line-height: 40px;
}
.page__navigation .header__navigation-item{
  height: 100%;
  vertical-align: middle;
}
.page__navigation .header__navigation-item a{
  color: #fff;
}
.page__header-phone{
  position: absolute;
  right: 0;
}
.page__header-phone a{
  margin-top: 0 !important;
}
@media (max-width: 650px){
  .page__navigation .page__navigation-main a{
    float: none;
    display: block;
    padding-left: 2%;
  }
  .page__navigation .header__navigation-list{
    text-align: left;
    margin: 0;
    padding: 0;
  }
}

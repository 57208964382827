//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;

.video{
  height: 650px;
  padding: 80px 0;
  width: 100%;
  position: relative;
}
.video__opasity{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255,255,255,0.6);
}
.video__slide-item-1, .video__slide-item-2{
  height: 250px;
  width: 350px;
  position: absolute;
  overflow: hidden;
  top:27%;
  z-index: -1;
}
.video__slide-item-1{
    left: 4%;
}
.video__slide-item-2{
  right: 4%;
}
.video__slide-item:last-child{
  right: 0;
}
.video__wrapper{
  position: relative;
  height: 430px;
  overflow: hidden;
}
.video__wrapper a{
  display: block;
  height: 100%;
  z-index: 1;
  transition: 0.2s;
}
.video__wrapper .icon{
  background-image: url("../img/video_play.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 68px;
  height: 68px;
  position: absolute;
  z-index: 0;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video__description{
  font-family: "GothaProBla", sans-serif;
  font-size: 1.8rem;
  margin-top: 20px;
  color: $text_color;
}

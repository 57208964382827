.rival__container{
  width:100%;
  font-size: 0;
  background-image: url("../img/rivar_bg.png");
  background-position: center;
  background-repeat: no-repeat;
}
.rival__title,.rival___description{
  display: inline-block;
  min-height: 750px;
  font-size: 1rem;
}
.rival__title{
  padding-top: 70%;
  margin-left: -50px;
  font-size: 12rem;
  font-family: "GothaProBol", sans-serif;
  line-height: 1;
  color: #fff;

}
.rival__title div{
  text-align: left;
  width: 90%;
  float: right;
}
.rival___description{
  //width: 67%;
  vertical-align: top;
  padding-left: 7.3%;
  //background-color: #fafafa;
}
.rival__text{
  margin-top: 13%;
  font-size: 1.3rem;

}
.rival__text p{
  margin-bottom: 10px;
  line-height: 2;
  text-align: justify;
}
// @media (max-width: 1200px){
//   .rival__title div{
//     text-align: center;
//     width: 90%;
//     font-size: 9rem;
//     float: none;
//     margin: 0 auto;
//   }
// }
//
@media (max-width: 1198px){
  .rival__title{
    font-size: 9rem;
  }
}
@media (max-width: 991px){
  .rival__title{
    font-size: 5rem;
    padding-top: 145%;
  }
}
@media (max-width: 767px){
  .rival__container{
    background-image: none;
  }
  .rival__title{
    background-image: url("../img/about_bg.png");
    font-size: 9rem;
    margin-left: 0;
    width: 100%;
    background-position: center;
    height: 200px;
    min-height: 0;
    text-align: center;
    padding-top: 8%;
  }
  .rival___description{
    padding-left: 0;
  }
}

//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;

.product__title{
  font-family: "GothaProBol", sans-serif;
  font-size: 4rem;
  margin-top: 85px;
  margin-bottom: 40px;
}
.product__card{
  min-height: 270px;
  border: 1px solid $text_color;
  padding-top: 25px;
  font-size: 0;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 20px;
  transition: 0.2s;
  &:after{
    display: block;
    content: '';
    background-color: $text_color;
    position: absolute;
    right: -10px;
    height: 1px;
    width: 22px;
    bottom: 48px;
  }
  &:hover{
    box-shadow: 0 0 25px #c8c8c8;
  }
}
.product__container{
  display: inline-block;
  font-size: 1rem;
  vertical-align: top;
  width: 50%;
}
.product__container.right{
  padding-left: 10px;
  padding-top: 33px;
}
.product__container.right a{
  font-family: "GothaProBol", sans-serif;
  letter-spacing: 1px;
  color: $text_color;
  transition:0.2s;
  &:hover{
    color: $brand_color;
    text-decoration: none;
  }
}
.product__img-wrapper{
  height: 200px;
  width: 97%;
  overflow: hidden;
  box-shadow: 10px 10px #f3f3f3;
  margin-left: -15px;
}
.product__card-title{
  font-family: "GothaProBla", sans-serif;
  margin-bottom: 10px;
  font-size: 1.8rem;
  letter-spacing: 0.5px;
}
.product__card-description{
  font-family: "GothaProLig", sans-serif;
  font-size: 1.2rem;
  width: 80%;
  margin-bottom: 10px;
  line-height: 2.1;
}

// Стили для правой карточки
.product__card_right .product__img-wrapper{
  box-shadow: -10px 10px #f3f3f3;
  margin-left: 25px;
}
.product__card_right .product__container.right{
  padding-left: 30px;
  padding-top: 33px;
}
.product__card_right:after{
  display: none;
}
.product__card_right:before{
  display: block;
  content: '';
  background-color: $text_color;
  position: absolute;
  left: -10px;
  height: 1px;
  width: 22px;
  bottom: 48px;
}

//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;

// margin padding variables

body {
  margin: 0;
  padding: 0;
  font-family: "GothaProReg", sans-serif !important;
  font-size: 13px !important;
  color: $text_color;
  line-height: 1.3 !important;
}
header{
  padding-top: 10px;
  margin-bottom: 10px;
}

// блоки
@import "page_navigation";
@import "advantages";
@import "controls";
@import "button";
@import "form";
@import "modal";

// секции страниц
@import "header";
@import "main_baner";
@import "products";
@import "questions";
@import "rival_decor";
@import "objects";
@import "design_deсition";
@import "video";
@import "subscribe";
@import "news";
@import "partners";
@import "map";
@import "footer";
@import "productpage";

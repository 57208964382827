//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;

.map__container{
  position: relative;
  height: 540px;
  width: 100%;
}
iframe{
  position: absolute;

}
.map__container ul{
  margin: 0;
  padding: 0;
  margin-top: 17%;
}
.map__container ul li {
  height: 130px;
  list-style-type: none;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 0;
}
.map__title, .map__contacts{
  display: inline-block;
  font-size: 1rem;
  position: relative;
  height: 100%;
}
.map__title{
  width: 42%;
  &:after{
    display: block;
    content: '';
    background-color: $brand_color;
    position: absolute;
    right: 0;
    top:15%;
    height: 70%;
    width: 1px;
  }
}
.map__contacts{
  width: 58%;
}

.map__title div, .map__contacts div{
  position: absolute;
  width: 100%;
  text-align: center;
  top:50%;
  transform: translateY(-50%);
}
.map__contacts div{

}
.map__title span{
  font-size: 2rem;
  font-family: 'GothaProBla',sans-serif;
}
.map__contacts span{
  display: block;
  line-height: 2.4;
  font-size: 1.5rem;
}

//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;

footer{
  width: 100%;
  min-height: 50px;
  line-height: 50px;
  background-color: $brand_color;
}
footer span{
  color: #fff;
  vertical-align: middle;
  font-family: "GothaProLig", sans-serif;
}

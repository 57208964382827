//color variables
$text_color:#333333;
$brand_color: #0e3e6d;
$border_color: #383d8d;
$red: #ac161e;

.button{
  font-size: 1.4rem;
  padding: 10px 0px;
  border: 1px solid;
  display: block;
  width: 220px;
  color: #fff;
  text-align: center;
  position: relative;
  letter-spacing: 1px;
  transition: 0.15s;
  &:hover{
    text-decoration: none;
    color:#e8e8e8;
    cursor: pointer;
  }
  &:after{
    display: block;
    content: '';
    background-color: #fff;
    position: absolute;
    right: -10px;
    top:18px;
    height: 1px;
    width: 22px;
  }
}
